<template>
  <div class="container-dota">
      <div class="container-xxl">
        <div class="row">
        <div class="col-md-7">
          <Main_left/>
        </div>
        <div class="col-md-5">
          <Main_right />
        </div>
      </div>
    </div>
    <Footer_main />
  </div>
</template>

<script>
import Main_left from "@/components/Main_left.vue";
import Main_right from "@/components/Main_right.vue";
import Footer_main from "@/components/Footer_main.vue";

export default {
  name: "HomeView",
  components: {Main_left, Main_right, Footer_main },
};
</script>

<style scoped>
.text-header {
  position: relative;
}
</style>
