<template>
        <div class="card bg-widjet">
            <div class="main-snow"></div>
            <h5 class="card-header">
                <img src="../assets/img/i_logotype_xmas.png">
            </h5>
            <div class="card-body p-3">
                <h5>Сезон: 59</h5>
                <p>Последный обновление: 24.08.2024</p>
                <p>Сейчас: {{dateToDota}}</p>
            </div>
          </div>

          <div class="last-videos mt-3">
            <h3>Последные обзоры</h3>
            <div class="rounded">
               <div class="videos-youtube">
                <iframe src="https://www.youtube.com/embed/zEhvFyRL-kc?si=C2GUXjVQyq8DCSl6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
               </div> 
               
                <iframe src="https://www.youtube.com/embed/fKlRQBnHEh8?si=8ZCh3733UOK4oU-k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>

          <div class="card mt-4">
            <h5 class="card-header bg-widjet">
                <img 
                class="img-fluid rounded"
                width="300"
                src="../assets/img/social-icons/warcraft-katapulta.webp" alt="">
            </h5>
            <div class="card-body">
                <div class="main-right-social d-flex justify-content-between">
                    <!-- <a href="https://wa.me/998970253676" target="_blank"><img src="../assets/img/social-icons/WhatsApp.svg" alt=""><br>Whatsapp</a> -->
                    <a href="https://vk.com/devg2" target="_blank"><img src="../assets/img/social-icons/vk.svg" alt=""><br>Вконтакте</a>
                    <a href="https://www.facebook.com/profile.php?id=100087746501429" target="_blank"><img src="../assets/img/social-icons/fb.svg" alt=""><br>Facebook</a>
                    <a href="https://t.me/G2Dev" target="_blank"><img src="../assets/img/social-icons/tg.svg" alt=""><br>Telegram</a>
                </div>
                <p class="card-text mt-3"><small class="text-muted">Время ответа — 5 минут</small></p>
            </div>
          </div>
</template>

<script setup>
    const dateDota = new Date();

    const dateToDota = dateDota.toLocaleDateString('en-GB');
</script>

<style scoped>
.main-snow{
    padding-top: 48px;
    background: url(../assets/img/snow-top.png) center 12px no-repeat;
}
iframe{
    width: 100%;
    height: 300px;
}
.main-right-social img {
    width: 35px;
    margin: 5px 0 5px 0;
}

.videos-youtube{
    width: 100%;
    height: 100%;
}
.bg-widjet{
    background-color: #e9eff5;
}
.card h5 , p,  a {
    font-family: 'vue-w25';
    color: black;
    font-size: larger;
}
.btn-vueAccess{
    border: 1px solid rgb(0, 255, 89);
    border-radius: 5%;
    background: rgb(137 209 130);
    color: white;
}
span{
    text-shadow: 0 0 5px #07bf57;
    font-size: 15px;
}

@media only screen and (max-width: 1290px) {
    .card h5 , p,  a {
        font-family: 'vue-w25';
        color: black;
        font-size: larger;
    }
}

@media only screen and (max-width: 420px) {
    .card h5 , p,  a {
        font-family: 'vue-w22';
        color: black;
        font-size: 9px;
    }
}
</style>