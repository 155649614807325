<template>
   <div class="container-dota">
    <img 
    width="450" 
    class="img-fluid"
    src="../assets/img/tiny_404.png" 
    alt="UmnickNotFound">
    <h3>Такой страницы не существует :)</h3>
    <li>К Сожалению, такой страницы не существует. Вероятно , У вас либо нет доступа , либо такой страницы здесь никогда не было.</li>
   </div>
</template>

<style scoped>
</style>

