<template>
    <div class="container-dota">
     <div class="container-xxl">
         <h3 class="text-titles mt-5">Гайд на Мипокей</h3>
 
         <div class="row mt-5">
             <div class="col d-flex justify-content-center">
                <iframe src="https://www.youtube.com/embed/jtMf0SKiACI?si=xBKjibqw1i-r5Kob" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
             </div>
         </div>
         <div class="row mt-5">
           <div class="col">
             <h3>Информация</h3>
             <p class="border-d">Версия: 2.0</p>
             <a href="https://mega.nz/file/zWIgRIAa#AydmtSCc_2VV5OgTtXdGMDGBcGryJMdPH7x6HmJOwks" target="_blank"><button class="btn btn-primary p-2">Скачать</button></a>
             <p>MeepoKey — программа для бинда горячих клавиш персонажа «Геомансер» в карте Dota Allstars игры Warcraft 3. Утилита полностью автоматизирует весь игровой процесс данного героя, вам не потребуется по 100 раз нажимать одни и те-же клавиши.</p>
           </div>
           <router-link to="/" style="border-bottom: 1px solid black"><p>Перейти к Главной странице</p></router-link>
         </div>
     </div>
    </div>
    <Footer_main/>
 </template>
 
 <script>
import Footer_main from "../components/Footer_main.vue"
export default {
    components: {Footer_main}
}
 </script>
 
<style scoped>
 p{
     font-family: 'vue-w25';
     font-size: larger;
     color: black;
 }
 .border-d{
     border: 1px solid black;
}
iframe{
    height: 560px;
}
@media(max-width: 499px){
    iframe{
        height: 260px;
    }
}
 
 </style>