<template>
  <div class="container-dota bg-vue22">
      <div class="row navbar-dota">
        <div class="col-md-10">
          <div class="nav-linkes">
            <div class="logo-d">
            <router-link to="/">
              <img 
              width="100" 
              src="./assets/img/warcraft_logo.png" 
              class="img-fluid">    
            </router-link>
          </div>
            <li class="Main-link">
              <router-link to="/">
                <img 
                src="./assets/img/glavnoe.png"
                class="img-fluid log-top" 
                ><br>Главная</router-link>
            </li>
            <li v-if="InfKeys < 1 || !userInfo == true">
              <router-link to="/news">
                <img 
                src="./assets/img/news.png"
                class="img-fluid log-top" 
                ><br>Новости</router-link>
            </li>
            <li>
              <router-link to="/abouthack">
                <img 
                src="./assets/img/Maps.png"
                class="img-fluid log-top" 
                ><br>Мапхак</router-link>
            </li>
            <li>
              <router-link to="/prices">
                <img 
                src="./assets/img/sunduk.png"
                class="img-fluid log-top" 
                ><br>Цены</router-link></li>
            <li>
              <router-link to="/Support">
                <img 
                src="./assets/img/support.png"
                class="img-fluid log-top" 
                ><br>Тех поддержка</router-link></li>
              <div class="dropdown" v-if="InfKeys > 0">
                <li class="dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                  <img 
                    src="./assets/img/Tutorials.png"
                    class="img-fluid log-top" 
                    ><br>
                  Активировать
                </li>
                <ul class="dropdown-menu" 
                aria-labelledby="dropdownMenu2">
                 <div class="d-flex flex-wrap">
                    <li 
                    v-if="userInfo">
                    <router-link to="/instructionSecretInformation" v-if="InfKeys > 0">
                      <img 
                      src="./assets/img/Tutorials.png"
                      class="img-fluid log-top" 
                      ><br>Инструкция
                    </router-link></li>
                    <li 
                    v-if="userInfo">
                    <router-link to="/tester" v-if="InfKeys > 0">
                      <img 
                      src="./assets/img/keys.png"
                      class="img-fluid log-top" 
                      ><br>Активация
                    </router-link></li>
                    <li 
                    v-if="userInfo">
                    <router-link to="/lastkeys" v-if="InfKeys > 0">
                      <img 
                      src="./assets/img/KeySklad.png"
                      class="img-fluid log-top" 
                      ><br>Cклад ключей
                    </router-link></li>
                 </div>
                </ul>
              </div>
          </div>
        </div>
        <div class="col-md-2 pt-1">
        <router-link to="/login" v-if="!userInfo == true">
          <li><span class="KnockLog">
            <img 
                src="./assets/img/mr_nikto.png" 
                class="img-fluid login-mob"
                ><br>Войти</span></li></router-link>
          <li><router-link to="/register" v-if="!userInfo == true">Регистрация</router-link></li>
            <router-link to="/account" v-if="userInfo">
                      <div class="w-100">
                        <img 
                          src="./assets/img/profile-logo.jpg" 
                          class="logo-prof"
                          width="70">
                          <span 
                          class="amount-txt">                          
                            <img src="./assets/img/keysAmount.png"
                            width="30"
                            class="rounded">{{InfKeys}}</span>
                      </div>
                      <div class="w-100">
                        <span>Привет {{ userInfo.username }}</span>
                      </div>
            </router-link>
        </div>
       </div>
    </div>
<router-view />
</template>

<script>
import { ref , onBeforeMount} from 'vue'
import axios from 'axios'
import configs from './configs/config'

export default {
   components: {},
    setup() {
      const user = window.localStorage.getItem('yf_395493')
      const userInfo = JSON.parse(user)
      const userJwt = window.localStorage.getItem('sc_359649')
      const userJwtG = userJwt

      const InfKeys = ref()

      onBeforeMount(() => {
        if(userJwtG) {
            getAmount();
        }        
      })

      const getAmount = async () => {
            await axios.get(`${configs.apiURL}/api/users/${userInfo.id}?fields=Amount`, {
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    Authorization: `Bearer ${userJwtG}`
                }
             })
                .then(response => {
                    InfKeys.value = response.data.Amount
                    
                })
                .catch(() => {
                    alert('Упс, что-то не так!')
                    localStorage.clear()
                    window.location.reload()
                });
        }
       
      return {
        getAmount,
        user,
        userInfo,
        userJwt,
        userJwtG,
        InfKeys
      }
    },
    methods: {
      logout(){
          window.localStorage.clear()
          window.sessionStorage.clear()
          window.location.reload()
        }
    }
}
</script>

<style>
*{
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
}
.amount-txt {
  font-size: 25px;
  font-family: 'vue-bold';
  color: #ffb500;
  text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #F00;
}
.img-prof{
  width: 25px;
  border-radius: 15%;
}
body {
  background: #f5f6fa;
}
.log-top {
  width: 75px;
}
.login-mob {
  width: 55px;
}
.logo-prof {
  width: 70px;
  max-width: 100%;
  height: auto;
  border-radius: 35px;
}

a{
  color:rgb(0, 0, 0);
  text-shadow: 1px 1px 1px rgb(0 0 0 / 25%);
  font-family: 'vue-w25';
  font-size: large;
}
h3 {
  font-family: 'vue-w22';
}
li{
  font-family: 'vue-w22';
  font-weight: 400;
  line-height: 22px;
  font-size: 20px;
  text-align: center;
  margin: auto;
}

span{
  font-family: 'vue-w22';
  font-weight: 300;
  font-size: 12px;
}
.KnockLog{
  color: black;
  padding-top: 20px;
  font-family: 'vue-w25';
  font-size: large;
}

.container-dota {
  padding-top: 75px;
}
.logo-d {
  display: block;
  font-weight: 700;
  color:black;
}
.nav-item {
  font-weight: bold;
  color: rgb(12, 11, 11);
  font-size: 19px;
}
.nav-linkes{
  display: flex;
  justify-content: space-evenly;
  font-weight: 500;
  align-items: center;
}
.login-reg{
  display: flex;
  justify-content: space-around;
  font-weight: 700;
}
.router-link{
  text-decoration: none;
}

.navbar-dota {
    position: fixed;
    height: 115px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 8px 0;
    background-color: #fff;
    box-shadow: 0 3px 10px rgba(0,0,0,.1);
    color: #434343;
}

#app {
font-family: 'vue-w22';
font-family: Avenir, Helvetica, Arial, sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
text-align: center;
color: #2c3e50;
}

.invoker-orbs {
animation: float 2s ease-in-out infinite;
}
.wrapper-dota {
  height: 100%;
}
iframe{
    width: 100%;
    height: 315px;
} 

@keyframes float {
0% {
    transform: translatey(10px);
}
50% {
    transform: translatey(-25px);
}
100% {
    transform: translatey(10px);
}
}

@media(max-width: 499px){
  a{
    font-size: small;
    font-family: 'vue-w25';
  }

  .KnockLog{
    font-family: 'vue-w25';
    font-size: small;
  }
}
</style>
