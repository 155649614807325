<template>
    <div>
        <div class="container-dota log-form" v-if="!userInfo">
                <form @submit.prevent="createLogin">
                    <h3>Войти</h3>
                    <img src="../assets/img/logo-fakafero.png"
                        width="100"
                        height="100"><br>
                     <div class="my-3">
                        <h3>Email/Nickname</h3>
                        <input type="text" v-model="identifier">
                    </div>
                    <div class="my-3">
                        <h3>Password</h3>
                        <input type="password" v-model="password">
                    </div>
                        
                    <button type="submit" :disabled="password.length < 3" class="btn btn-primary">
                            Войти
                    </button>
                </form>
        </div>
        <h3 
        v-else
        class="container-dota">Вы уже Авторизованы. Вы участник нашей секты</h3>
    </div>
</template>
<script setup>
import axios from 'axios'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import configs from '../configs/config'

        const identifier = ref("")
        const password = ref("")
        const router = useRouter();
        const AccessLogged = () => {
            router.push({path:'/'})
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        }
        const user = ref('')
        const userTok = ref('')
        const userInfo = localStorage.getItem('yf_395493')

        const createLogin = async () => {
           await axios.post(`${configs.apiURL}/api/auth/local/`, 
            {
                "identifier": identifier.value,
                "password": password.value
            })
            .then(response => {
                user.value = response.data
                userTok.value = response.data.jwt
                window.localStorage.setItem('sc_359649', response.data.jwt)
                window.localStorage.setItem('yf_395493', JSON.stringify(response.data.user))
                window.localStorage.setItem("identification", JSON.stringify(response.data.user.id));
                AccessLogged()
            })
            .catch(error => {
                if(error) {
                    alert('Упс что-то пошло не так!')
                }  
              });
            }
</script>
<style scoped>
</style>
