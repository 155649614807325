<template>
  <div class="container-dota">
   <div class="container-xxl">
    <div class="row">
        <div class="col-md">
            <h3>Карты <br>VISA/МИР</h3>
            <img src="../assets/img/wallets/Visa_2014.svg.png" alt="">
            <h3 class="mt-2">4890 4947 8741 9324</h3>
            <img class="img-thumbnail rounded" src="../assets/img/wallets/Mir-01.png" alt="">
            <h3>МИР</h3>
            <h3 class="mt-2">2200 7302 4858 2682</h3>
            <h3>Имя: СТАНИСЛАВ</h3>
            <h3>Фамилия: БОРИСОВ</h3>
            <h3>Отчество: ВИТАЛЬЕВИЧ</h3>
            <button 
            @click="CopyWallet2"
            class="btn btn-primary">Скопировать Visa</button><br>
            <button 
            @click="CopyWallet3"
            class="btn btn-primary mt-2">Скопировать Мир</button>
        </div>
        <div class="col-md">
            <h3>Uzcard</h3>
            <img class="img-fluid" src="../assets/img/wallets/Uzcard-01.png" alt="">
            <h3>8600 0304 8010 2288</h3>
            <button 
            @click="CopyWallet"
            class="btn btn-primary">Скопировать uzCard</button>
        </div>
        <div class="col-md">
            <h3>Donate System</h3>
            <img src="" alt="">
           <h3><a href="https://www.donationalerts.com/r/aljafar" target="_blank">Donate #1</a></h3>
           <h3><a href="https://new.donatepay.ru/@Aljafar" target="_blank">Donate #2</a></h3>
           <h3>For (No-russian users)</h3>
        </div>
    </div>
    <h3 class="mt-2"><router-link to="/account">К главной странице</router-link></h3>
   </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

    const uzCard = ref('8600 0304 8010 2288')
    const RU_MIP = ref('2200 7302 4858 2682')
    const RU_VISA = ref('4890 4947 8741 9324')

    const CopyWallet = () => {
            try {
                navigator.clipboard.writeText(uzCard.value)
            } 
            catch(e) {
                    console.log(e)
                }
            }
    const CopyWallet2 = () => {
            try {
                navigator.clipboard.writeText(RU_VISA.value)
            } 
            catch(e) {
                    console.log(e)
                }
            }
    const CopyWallet3 = () => {
            try {
                navigator.clipboard.writeText(RU_MIP.value)
            } 
            catch(e) {
                    console.log(e)
                }
            }
</script>

<style scoped>
</style>