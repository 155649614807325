<template>
   <div class="container-dota">
    <div class="container-xxl">
        <h3 class="text-titles mt-5">Гайд на Каелкей</h3>

        <div class="row mt-5">
            <div class="col d-flex justify-content-center">
                <iframe src="https://www.youtube.com/embed/XqIWCR4waRc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="row mt-5">
          <div class="col">
            <h3>Информация</h3>
            <p class="border-d">Версия: 2.0</p>
            <a href="https://mega.nz/file/HLoAES5Y#yFiSRehBzVIj5SuYe3MDgLl-XmUfm4--59gZv8Y8d7U" target="_blank"><button class="btn btn-primary p-2">Скачать</button></a>
            <p>KaelKey поможет вам чувствовать в игре свободно, теперь играть Kael - Invoker станет намного легче, любителям данного героя советую. Теперь вам не потребуется много времени для того чтобы переключить на другую магию, всё просто, одним нажатием кнопки можно сменить любой скилл!
            </p>
          </div>
          <router-link to="/" style="border-bottom: 1px solid black"><p>Перейти к Главной странице</p></router-link>
        </div>
    </div>
   </div>
   <Footer_main/>
</template>

<script>
import Footer_main from "../components/Footer_main.vue"
export default {
    components: {Footer_main}
}
</script>

<style scoped>
p{
    font-family: 'vue-w25';
    font-size: larger;
    color: black;
}
.border-d{
    border: 1px solid black;
}
iframe{
    height: 560px;
}
@media(max-width: 499px){
    iframe{ 
        height: 260px;
    }
}
</style>