<template>
    <div class="container-dota">
     <div class="container-xxl">
         <h3 class="text-titles mt-5">DotA Theme Manager v5</h3>
 
         <div class="row mt-5">
             <div class="col d-flex justify-content-center">
                <img src="../assets/img/guides/dotatheme.jpg" 
                class="img-fluid">
             </div>
         </div>
         <div class="row mt-5">
           <div class="col">
             <h3>Информация</h3>
             <p class="border-d">Версия: 5.0</p>
             <a href="https://mega.nz/file/GSQjWRyL#bg-ontsmATrMasHACXaY5aKsjEOwzVJcU_HUsvADvas"><button class="btn btn-primary p-2">Скачать</button></a>
             <p>
                DotA Theme Manager проще говоря: DTM - позволяет изменить внешний вид местности, все еще ​​позволяет играть с людьми, которые не используют DotA Менеджер Тема - на battle.net, Garena , Hamachi, просто везде.
                Несколько темы доступны: пляж, Степи, снег, ад, города и высокогорья, все из которых имеет уникальный дизайн!
                Многие из вас будут удивлены, как это работает ... ну, это просто: DTM обменов текстур ландшафта, деревья и декорации в файлах MPQ - в основном для хранения файлов WC3. Этот процесс легко обратимы, не вредя WC3 на всех, и это не вызывает никаких проблем во взаимодействии с другими игроками, которые не используют DTM. Крошечная негативный момент в том, что также изменяет текстуры для других карт, которые могут выглядеть немного плохо, но если вы восстановить его в оригинале снова работает, так что никогда не проблематично.
             </p>
             <ul>
                <li>Руководство по установке:</li> 
                <li>1. Скачать новую версию DotA тема менеджер</li>
                <li>2. Распакуйте файл в папку Warcraft - в большинстве случаев C: \ Program Files \ Warcraft III .</li>
                <li>3. НЕ переместить или переименовать любой из файлов, которые вы распаковали. Путь должен быть: " \ Warcraft III \ DTMV5 \ "</li>
                <li>4. Если вы хотите, то на рабочем столе можно создать ярлык на исполняемый файл.</li>
                <li>5. убедитесь, что оба ваших WC3 и ваши World Editor закрыты!</li>
                <li>6. Go!</li>
              </ul>
           </div>
         </div>
     </div>
    </div>
    <Footer_main/>
 </template>
 
<script>
import Footer_main from "../components/Footer_main.vue"
export default {
    components: {Footer_main}
}
</script>
 
 <style scoped>
 p{
    font-family: 'vue-w25';
    font-size: larger;
    color: black;
 }
 .border-d{
     border: 1px solid black;
 }
 iframe{
     height: 560px;
 }
 @media(max-width: 499px){
    iframe{ 
        height: 260px;
    }
}
 
 </style>