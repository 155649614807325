<template>
  <div 
  class="Regulator"
  v-if="userKeys"> 
    <p>Ваш последний ключ это</p>
    <li style="font-size: 6px;">{{ HWID_KEY }}</li>
    <h3>{{ HWID_TIME }}</h3>
    <li>Год, Месяц , День</li>
    <button 
        @click="CopyHwid"
        class="btn btn-primary mb-4">Взять код</button>
    <h3 style="color: red;">Внимание!</h3>
    <p>Это ваш старый ключ! Это склад!</p>
    <p>Если ваш ключ устарел пожалуйста перейдите в этот раздель: <router-link to="/tester">ТыЫк</router-link></p>
  </div>
  <div v-else>
    <notFound/>
  </div>

</template>
<script setup>
import notFound from '../pages/NotFound.vue'
import { ref, onBeforeMount } from 'vue'
import axios from 'axios'
import configs from '../configs/config.js'

    const userKeys = window.localStorage.getItem('identification')
    const userKeysA = userKeys
    const userJwt = window.localStorage.getItem('sc_359649')
    const userJwtG = userJwt
    const HWID_TIME = ref('')
    const HWID_KEY = ref('')

    const getHwid = async () => {
            await axios.get(`${configs.apiURL}/api/users/${userKeysA}/?HWID_KEYS&HWID_TIME`, {
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    Authorization: `Bearer ${userJwtG}`
                }
             })
            .then(response => {
                HWID_KEY.value = response.data.HWID_KEYS
                HWID_TIME.value = response.data.HWID_TIME
             })
            .catch(err => {
                console.log(err)
             })
                
        }
        
        
    const CopyHwid = () => {
            try {
                navigator.clipboard.writeText(HWID_KEY.value)
                alert('Вы успешно скопировали ключ!')
            } catch(e) {
                 console.log(e)
            }
        }
    onBeforeMount(() => {
        getHwid()
    })

</script>

<style scoped>
li{
 color: black;
}
.Regulator{
    margin-top: 95px;
    text-align: center;
}
</style>