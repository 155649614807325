<template>
 <div class="container-dota" v-if="userInfo.isPlayer == true">
    <h3>Добро пожаловать в Мир Ключей!</h3>
    <div class="container-xxl keys-information mt-3">    
       <div v-if="keyDota.length !== 0">
             <span> {{ keyDota.key }} </span><br>
             <button class="btn btn-primary btn-sm" @click="copy">Скопировать</button>
         </div>
       <p v-else> Пока ключей нет! Сначало нужно отправить запрос!</p> 
     </div>

     <form @submit.prevent="createPost">
         <label for="">ID</label><br>
         <input
             v-model="hardware_id"
             type="text"
             placeholder="HARDWARE_ID"
         ><br>
         <label>Время</label><br>
          <input 
            type="date"
            v-model="date"
            min="2022-11-01"
            >
     </form>
    <button class="btn btn-primary mt-2" @click="createPost">Отправить</button>
    </div>
 <div v-else>
    <notFound/>
 </div>
</template>
 
<script>
import axios from "axios"
import { ref } from 'vue'
import notFound from '../pages/NotFound.vue'
 
 export default {
    components: {notFound },
     setup() {
         const keyDota = ref('')
         const customer_name = ref("Qudratilla")
         const email = ref("Qudratilla@mail.ru")
         const hardware_id = ref("ВашID")
         const date = ref('2022-11-00')
        

         const createPost = async () => {
            await axios.post('http://199.180.249.164:8864/licenses', 
             {
                 "customer_name": userInfo.username,
                 "email": userInfo.email,
                 "hardware_id": hardware_id.value,
                 "expiration_date": date.value,
                 "key": "hackCupUser"
            })
            .then(response => {
                 keyDota.value = response.data
            })
         }
            return {
             createPost,
             keyDota,
             customer_name,
             email,
             hardware_id,
             date,
             user,
             userInfo,
             userJwt,
             userJwtG 

            }
     },
     methods: {
         copy() {
             try {
                 navigator.clipboard.writeText(this.keyDota.key)
             } catch(e) {
                 console.log(e)
             }
         },
            
     }
 }
 </script>
 
 <style scoped>
.keys-information {
    background-color: #f2f5fa;
}    
.copy-button {
    background-color: var(--vt-c-bg-mute);
    transition: background-color .5s;
    padding: 5px 12px;
}
.Get-keys {
     margin: auto;
     margin-bottom: 25px;
     border: 1px solid black;
     border-radius: 15px;
     color: #333;
     padding: 5px;
}
p{
    font-family: 'vue-w22';
    text-align: center;
    color: #333;
    font-weight: 300;
    font-size: 15px;
}
span {
    font-family: 'vue-w22';
    text-align: center;
    color: #333;
    font-size: 5px;
}
 
</style>