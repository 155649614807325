<template>
     <div class="container-doter">
         <div class="container-xxl">
            <h3>Демонстрация</h3>
            <div class="row">
               <div class="col-md">
               <button @click="toggler = !toggler">
               <img
                  class="img-fluid"
                  src="../assets/img/camera_hak.png"
                  alt="Animals"
                  itemprop="image"
               />
            </button>
            <h3>Камера Система</h3>
               </div>
               <div class="col-md">
                  <button @click="toggler = !toggler">
               <img
                  class="img-fluid"
                  src="../assets/img/peredacha.png"
                  alt="Animals"
                  itemprop="image"
               />
            </button>
            <h3>Передача голдов</h3>
               </div>
               <div class="col-md">
                  <button @click="toggler = !toggler">
                  <img
                     class="img-fluid"
                     src="../assets/img/wardi_sentri.png"
                     alt="Animals"
                     itemprop="image"
                  />
               </button>
               <h3>Варды Сентри</h3>
               </div>
            </div>
            <!-- вторая - 3штук фото -->
            <div class="row">
               <div class="col-md">
               <button @click="toggler = !toggler">
               <img
                  class="img-fluid"
                  src="../assets/img/kto_zakogo.png"
                  alt="Animals"
                  itemprop="image"
               />
            </button>
            <h3>До начало игры Информация</h3>
               <p>Кто за кого попал</p>
               </div>
               <div class="col-md">
                  <button @click="toggler = !toggler">
               <img
                  class="img-fluid"
                  src="../assets/img/demo_illuision.png"
                  alt="Animals"
                  itemprop="image"
               />
            </button>
            <h3>Иллюзия детектор</h3>
               </div>
               <div class="col-md">
                  <button @click="toggler = !toggler">
                  <img
                     class="img-fluid"
                     src="../assets/img/skrin_cooldown.png"
                     alt="Animals"
                     itemprop="image"
                  />
               </button>
               <h3>Перезарядка (Противников)</h3>
               </div>
            </div>
         </div>
         <FsLightbox
            :toggler="toggler"
            :sources="[
               require('../assets/img/camera_hak.png'),
               require('../assets/img/peredacha.png'),
               require('../assets/img/wardi_sentri.png'),
               require('../assets/img/kto_zakogo.png'),
               require('../assets/img/demo_illuision.png'),
               require('../assets/img/skrin_cooldown.png')
               ]"
         ></FsLightbox>
      </div>
      
</template>
<script scoped>
import FsLightbox from "fslightbox-vue/v3";

export default {
  components: { FsLightbox },
  data() {
    return {
      toggler: false,
    };
  },
};
</script>
<style scoped>
.container-doter{
   padding-top: 60px;
}
h3{
   margin-top: 15px;
   font-family: 'vue-w25';
}
p{
   font-family: 'vue-w25';
}
</style>