<template>
    <div class="container-dota">
      <div class="container-xxl"> 
        <div class="row">
          <div class="col-md mb-2">
            <div class="card-main log-form2">
            <h2>Фулл Мапхак</h2>  
            <h3>11$ / 1099руб <br>Популярно</h3>  
            <div class="card-dota">
                <div class="head-card">
                <div class="img-card">
                    <img :src="logo.imglogo" class="invoker-orbs">
                </div>
                </div>
                    <div class="info-card">
                        <p>Доступ ко всему функционалу</p>
                        <p>Быстрая помощь в поддержке</p>
                        <p>Доступ к Быстрым обновлением!</p>
                        <p class="mb-5">Доступ к Панели управлении</p>
                        <p><router-link to="/abouthack">Демонстрация хака</router-link></p>
                        <a target="_blank" href="https://t.me/g2dev">
                            <div class="buyknopka2">
                                <span class="icon_maphack2"></span>
                                <span class="buyknopka_title2">Купить</span>
                                <span class="buyknopka_portred2"></span>
                            </div>
                        </a>
                    </div>
            </div>
           </div>    
          </div>
        <div class="col-md mb-2">
           <div class="card-main log-form2">
            <h2>Фулл Мапхак</h2>  
            <h3>18$ / 1599руб <br>Гарантия (Сезон)</h3>  
            <div class="card-dota">
                <div class="head-card">
                <div class="img-card">
                    <img :src="logo.imglogo" class="invoker-orbs">
                </div>
                </div>
                    <div class="info-card">
                        <p>Доступ ко всему функционалу</p>
                        <p>Быстрая помощь в поддержке</p>
                        <p>Доступ к Быстрым обновлением!</p>
                        <p class="mb-5">Доступ к Панели управлении</p>
                        <p><router-link to="/abouthack">Демонстрация хака</router-link></p>
                        <a target="_blank" href="https://t.me/g2dev">
                            <div class="buyknopka2 mb-5">
                            <span class="icon_maphack2"></span>
                            <span class="buyknopka_title2">Купить</span>
                            <span class="buyknopka_portred2"></span>
                            </div>
                        </a>
                    </div>
            </div>
           </div>     
            </div>
         <div class="col-md mb-2">
           <div class="card-main log-form2">
            <h2>Елита (Скидка!)</h2>  
            <h3>55$ / 4400руб <br>Гарантия (год)</h3>  
            <div class="card-dota">
                <div class="head-card">
                <div class="img-fluid">
                    <img :src="logo.imglogo" class="invoker-orbs">
                </div>
                </div>
                    <div class="info-card">
                        <p>Доступ ко всему функционалу</p>
                        <p>Быстрая помощь в поддержке</p>
                        <p>Доступ к Быстрым обновлением!</p>
                        <p class="mb-5">Доступ к Панели управлении</p>
                        <p><router-link to="/abouthack">Демонстрация хака</router-link></p>
                        <a target="_blank" href="https://t.me/g2dev">
                            <div class="buyknopka2 mb-5">
                            <span class="icon_maphack2"></span>
                            <span class="buyknopka_title2">Купить</span>
                            <span class="buyknopka_portred2"></span>
                            </div>
                        </a>
                    </div>
            </div>
           </div>     
          </div>
        </div>
      </div>
    </div>
    <router-view />
</template>
<script>
export default{
    data() {
        return {
            logo: {
                imglogo: require("../assets/img/invoker_orbs.png")
            }
        };
    }
}
</script>

<style scoped>
.log-form2{
    width: 99%;
    background: #fff;
    padding: 25px 0 5px 0;
    border-radius: 4%;
}
.log-form2 p{
    font-size: 15px;
    font-family: 'vue-w22';
    color: black;
}
.log-form2 h3{
    font-size: xxx-large;
    font-family: 'vue-w25';
    color: black;
}

.log-form2 label{
  font-size: 15px;
}
.card-dota {
    width: 100%;
    height: 400px;
    text-align: center;
}
.col-md h2 {
    font-family: 'vue-w25';
    font-size: xx-large;
    color: black;
}

.card-dota h3 {
    font-family: 'vue-w22';
    font-size: 21px;
    color: black;
}
.card-dota h4 {
    font-family: 'vue-w22';
    font-size: 14px;
    color: whitesmoke;
}
.info-card p {
    font-size: medium;
    font-family: 'vue-w25';
    color: black;
} 
.head-card {
    width: 100%;
    height: 30%;
}
.info-card {
    width: 100%;
    height: 30%;
}
img {
    width: 250px;  
}
.card-main:hover {
    border: 2px solid red;
    box-shadow: 1px 10px 40px -9px rgba(255,0,14,0.75);
}
</style>