<script setup>
import configs from '../configs/config.js'
import axios from 'axios'
import { onBeforeMount, ref } from 'vue'

  const userJwt = window.localStorage.getItem('sc_359649')
  const userJwtG = userJwt

  const newsQuoute = ref('')
  const newsQuoute2 = ref('')

  const postingNews = async () => {
              await axios.get(`${configs.apiURL}/api/newsdotas/`, {
                  headers: {
                      'Content-type': 'application/json',
                      'Accept': 'application/json',
                      Authorization: `Bearer ${userJwtG}`
                  }
              })
              .then(response => {
                newsQuoute.value = response.data
              })
              .catch(err => {
                      console.log(err)
              })
                  
        }
  const getImage = async () => {
              await axios.get(`${configs.apiURL}/api/newsdotas?populate=photo`, {
                  headers: {
                      'Content-type': 'application/json',
                      'Accept': 'application/json',
                      Authorization: `Bearer ${userJwtG}`
                  }
              })
              .then(response => {
                newsQuoute2.value = response.data
                console.log(response.data)
              })
              .catch(err => {
                      console.log(err)
              })
                  
        }

        onBeforeMount(async () => {
           await postingNews();
           await getImage()
        })    

</script>
<template>
  <div class="container-dota">
    <h3>Новости</h3>
     <p>Скоро появится что то...</p>
  </div>
</template>
<style scoped>
.card-news {
  width: 100%;
  height: 360px;
  border-radius: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
}
.card-head {
  width: 100%;
  height: 80%;
}
.card-text {
  width: 100%;
  height: 20%;
}
.card-head img {
  padding: 25px;
  object-fit: cover;
}

h4{
  font-size: 19px;
  font-weight: 700;
}
p{
  font-size: 32px;
  font-weight: 800;
  font-family: 'vue-w25';
}
</style>